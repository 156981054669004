import React, { useState } from "react";
import { residences } from "../../assets/data/residences";

function Caracteristiques({ residence }) {
   const [Default,setDefault]=useState(true);
  // const [name, setName] = useState();
  const id = localStorage.getItem("idresid") || 1;
  return (
    <>
      <div className="caracteristiques" id={"caracteristiques"} >
        <div className="container">
          <div className="title">
            <h2>
              Caractéristiques<span>/</span>
            </h2>
          </div>
          <div className="content">

            {
               
              Object.keys(residence).length > 0 && residence['caracterstique'].length>0 ? residence.caracterstique?.map((caract, k) => {
                    return (
                      
                      
                      <div className="item">
                        <div className="item-title">
                        
                          <span>{caract.id}</span>
                          <h3>{caract.title}</h3>
                        </div>
                        <ul>

                          {caract.data.map(info => {
                            return (<li>{info}</li>)
                          })}

                        </ul>
                      </div>

                    )

                  }) :
                  <>
                  <div className="item">
                    <div className="item-title">
                      <span>1</span>
                      <h3>Équipements collectifs, avantages et sécurité :</h3>
                    </div>
                    <ul>
                      <li>Entrées sécurisées et contrôlées</li>
                      <li>Parking en sous-sol avec portail automatique</li>
                      <li>Ascenseurs de grande marque avec accès au parking</li>
                      <li>Système de Télédistribution collective</li>
                      <li>Respect des normes parasismique RPS 2000</li>
                      <li>Contrôle technique du gros œuvre, étanchéité, électricité plomberie, climatisation et ascenseurs</li>
                      <li>Contrôle du béton et du sol réalisé par un laboratoire agrée.</li>
                    </ul>
                  </div>
                  <div className="item">
                    <div className="item-title">
                      <span>2</span>
                      <h3>Chambres et Salons :</h3>
                    </div>
                    <ul>
                      <li>Revêtement sol en marbre ou compact</li>
                      <li>Portes de fabrication européenne</li>
                      <li>Fenêtre en menuiserie d'aluminium</li>
                      <li>Volets roulant électrique</li>
                      <li>Climatisation individuelle.</li>
                    </ul>
                  </div>
                  <div className="item">
                    <div className="item-title">
                      <span>3</span>
                      <h3>Salles de Bain :</h3>
                    </div>
                    <ul>
                      <li>Sanitaire de fabrication européenne</li>
                      <li>Robinetterie de grande marque</li>
                      <li>Revêtement murs en carreaux</li>
                      <li>Eclairage indirect.</li>
                    </ul>
                  </div>
                  <div className="item">
                    <div className="item-title">
                      <span>4</span>
                      <h3>Cuisine :</h3>
                    </div>
                    <ul>
                      <li>Cuisine préinstallée et équipée</li>
                      <li>Evier en inox avec mitigeur de marque</li>
                      <li>Plan de travail en granite</li>
                      <li>Espace encastrable pour l’électroménager</li>
                    </ul>
                  </div>
                </>
                
   
}              
       

          </div>
        </div>
      </div>


      

    </>
    );
}

export default Caracteristiques;
