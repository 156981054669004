import React from "react";

function Hero() {
  return (
    <div className="hero">
      <div className="container">
        <div className="title">
          <h1>AYA Briques</h1>
          <p>Fabrication des briques</p>
        </div>
        <div className="content">
          <div className="content-img">
            <img src="../images/x22.webp" alt="" />
          </div>
          <div className="content-container">
            <div className="content-p">
              <p>
                AYA Briques est votre interlocuteur unique pour vos projets
                immobiliers. Engagée depuis plus de 10 ans, dans la réalisation
                des meilleures produits de construction.
              </p>

              <p>
                La satisfaction clients est au cœur de ces priorités. AYA
                Briques vous accompagne à toutes les étapes de votre projet et
                s’assure du bon déroulement des opérations avant, pendant et
                après la livraison.
              </p>

              <p>
                <span>Pour nous rendre visite</span>
                <br />
                <strong>Address:</strong> Km 24 - Route de Ait Ourir Ouarzazate
                - Chwiter Marrakech
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
