import React from "react";

function Engagement() {
  return (
    <div className="engagement" id="engagements">
      <div className="container">
        <div className="title">
          <h2>
            Nos engagements<span>/</span>
          </h2>
        </div>
        <div className="content">
          <div className="item">
            <div className="item-title">
              <img
                src="../icons/eng-quality.svg"
                alt="UNE MEILLEURE QUALITÉ DE PRODUIT"
              />
              <h3>Une meilleure qualité de produit</h3>
            </div>
            <p>
              Construire des biens qui font du bien, c’est notamment intégrer
              dans 100% de nos logements des produits de qualité supérieur et
              économique
            </p>
          </div>
          <div className="item">
            <div className="item-title">
              <img
                src="../icons/eng-lumiere.svg"
                alt="UNE MEILLEURE QUALITÉ DE PRODUIT"
              />
              <h3>Plus de lumière, moins de bruit</h3>
            </div>
            <p>
              Construire des biens qui font du bien, c’est vous garantir des
              appartements avec des surfaces vitrées supérieures au standard et
              un confort acoustique renforcé.
            </p>
          </div>
          <div className="item">
            <div className="item-title">
              <img
                src="../icons/eng-perennite.svg"
                alt="UNE MEILLEURE QUALITÉ DE PRODUIT"
              />
              <h3>Qualité et pérennité des constructions</h3>
            </div>
            <p>
              Construire des biens qui font du bien, c’est vous proposer des
              projets certifiés, conçus avec des matériaux durables et
              respectueux de l’environnement.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Engagement;
