import React from "react";

function Services() {
  return (
    <div className="service" id="services">
      <div className="container">
        <div className="title">
          <h2>
            Services<span>/</span>
          </h2>
        </div>
        <div className="content">
          <div className="item">
            <div className="item-title">
              <img
                src="../icons/imp.svg"
                alt="UNE MEILLEURE QUALITÉ DE PRODUIT"
              />
              <h3>Importation de produits</h3>
            </div>
            <p>
              Nos produits proviennent de l’Europe et de l'Asie. Et Pour mener à
              bien nos importations, nous entretenons des relations quotidiennes
              avec les compagnies maritimes et de transport
            </p>
          </div>
          <div className="item">
            <div className="item-title">
              <img
                src="../icons/vente.svg"
                alt="UNE MEILLEURE QUALITÉ DE PRODUIT"
              />
              <h3>Vente de produits</h3>
            </div>
            <p>
              Chez Sté Yamani, vous avez de quoi bien équiper votre bien. Un
              large choix de produits à votre disposition : Ciment, Briques,
              Platre et tout autre produit nécessaire à la construction
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
