import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";

function NavBar({ array }) {
  const [menu, setMenu] = useState(false);
  const closeMenu = () => setMenu(false);
  const btn = useRef();
  const [orientation,setOrientation]=useState('');



  useEffect(() => {
    const scrolled = () => {
      if (window.pageYOffset < 100) {
        btn.current.classList.add("back-to-b");
        //btn.current.href = "home";
        setOrientation("home");
      }
      if (window.pageYOffset === 0) {
        btn.current.classList.remove("back-to-b");
        //btn.current.href = array[0].path;
        setOrientation("footer");
      }
    };
    window.addEventListener("scroll", scrolled);
    window.addEventListener("click", scrolled);
  });

  const handlescroll=(idelement)=>{
    let offsetTop  = document.getElementById(idelement).offsetTop;
    window.scrollTo({
    top: offsetTop-100, 
    behavior: "smooth"
});
if(orientation==="home"){
  btn.current.classList.remove("back-to-b");
  setOrientation("footer");

}else{
  btn.current.classList.add("back-to-b");
  setOrientation("home");
}
  }
  return (
    <>
      <a onClick={()=>{handlescroll(orientation)}} className="back-to-t" ref={btn}>
        <img src="../icons/flech-black.svg" alt="" />
      </a>
      <div className={`menu-mobile ${menu ? "" : "close-menu"}`} id="home">
        <ul>
          <li>
            <a
              href="/#top"
              id="top"
              onClick={() => {
                closeMenu();
                window.scrollTo(0, 0);
              }}
             
            >
              Acceuil
            </a>
          </li>
          {array.map((x, index) => (
            <li key={index}>
              <a
                onClick={()=>{setMenu(false);handlescroll(x.path)}}
                
                   >
                {x.link}
              </a>
            </li>
          ))}
          {/* <hr />
          <li>
            <a onClick={closeMenu} href="#nosentreprises">
              Nos entreprises
            </a>
          </li>
          <hr />
          <li>
            <a onClick={closeMenu} href="#residences">
              Résidences
            </a>
          </li>
          <hr />
          <li>
            <a onClick={closeMenu} href="#complexe-amine">
              Complexe Amine
            </a>
          </li> */}
        </ul>
        <div className="map">
          <div className="content">
            <h2>Nous Somme toujours ici</h2>
            <div>
              <p>
                Villa B4 Lotissement Ali Ouhmad BD Prince MY Abdellah, Marrakech
              </p>
              <div>
                <div>
                  <Link to="tel:+212524449534">+212 5 24 44 95 34</Link>
                  <Link to="tel:+212661053981">+212 6 61 05 39 81</Link>
                </div>
                <div>
                  <Link to="mailto:steyamani@gmail.com">
                    Steyamani@gmail.com
                  </Link>
                  <Link to="mailto:contact@aghouatim.com">
                    contact@aghouatim.com
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav>
        <Link to="/" onClick={() => window.scrollTo(0, 0)}>
          <img src="../icons/logo.svg" alt="Aghouatim logo" />
          <img src="../icons/logo-icon.svg" alt="Aghouatim logo" />
        </Link>
        <ul>
          <li>
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              Acceuil
            </Link>
          </li>
          {/* <li>
            <a href="/#engagements">Nos engagements</a>
          </li>
          <li>
            <a href="/#nosentreprises">Nos entreprises</a>
          </li>
          <li>
            <a href="/#residences">Résidences</a>
          </li>
          <li>
            <a href="/#complexe-amine">Complexe Amine</a>
          </li> */}
          {array.map((x, index) => (
            <li key={index}>
              <a  onClick={()=>{handlescroll(x.path)}}>{x.link}</a>
            </li>
          ))}
          <li>
            {/* <a className="contact" onClick={()=>{handlescroll("contact")}}>
              Contact <img src="../icons/flech-white-contact.svg" alt="" />
            </a> */}
            <Link to={{
    pathname: "/",
    hash: "#contact",
    state: { name: "contact" }
  }} className="contact"
  onClick={()=>{handlescroll('contact')}}>
              Contact <img src="../icons/flech-white-contact.svg" alt="" />
            </Link>
            {menu ? (
              <img
                src="../icons/close-btn.svg"
                alt=""
                onClick={() => setMenu((x) => !x)}
              />
            ) : (
              <img
                src="../icons/menu-btn.svg"
                alt=""
                onClick={() => setMenu((x) => !x)}
              />
            )}
          </li>
        </ul>
      </nav>
    </>
  );
}

export default NavBar;
