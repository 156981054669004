import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Project_Item from "./homepage/Project_Item";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setResidence } from "../redux/reducers/globalSlice";

export default function App({ elements }) {
  
  const handleClick=(x)=>{
    localStorage.clear();
    localStorage.setItem('idresid', x.id)
    localStorage.setItem('nameresid', x.name)
    console.log(localStorage.getItem("idresid"))
  }
  const array = ["", "", "", "", "", "", ""];
  return (
    <>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={0}
        pagination={{
          type: "fraction",
        }}
        // loop={true}
        // loopFillGroupWithBlank={true}
        navigation={true}
        className="mySwiper"
        modules={[Pagination, Navigation]}
      >
        {elements.map((x, index) => (
          <SwiperSlide key={index}>
          
            <Link to={"/project/" + x.path} onClick={()=>{localStorage.clear();
    localStorage.setItem('idresid', x.id);
    localStorage.setItem('nameresid', x.name);
    console.log(localStorage.getItem("idresid"));}}>
              <Project_Item element={x} />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
