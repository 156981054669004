import React from "react";
import Swiper from "../Swiper";
import {residences} from "../../assets/data/residences";

function Residences() {
  const sliderClick = (way) => {
    return () => {
      const swiper_btn = document.querySelector(`.residences .swiper-button-${way}`);
      swiper_btn.click();
    };
  };

  return (
    <div className="residences" id="residences">
      <div className="container">
        <div className="title">
          <h2>
            Résidences<span>/</span>
          </h2>
          <div className="btns">
            <button onClick={sliderClick("prev")}>
              <img src="../icons/flech-white.svg" alt="" />
            </button>
            <button onClick={sliderClick("next")}>
              <img src="../icons/flech-white.svg" alt="" />
            </button>
          </div>
        </div>
        <div className="content">
          <Swiper elements={residences} />
        </div>
      </div>
    </div>
  );
}

export default Residences;
