/* eslint-disable no-multi-str */
export const residences = [
  {
    id: 1,
    name: "Résidence Aya",
    subtitle:"Plateaux bureaux, Locaux commerciaux",
    img: "../images/aya-cover.jpg",
    path: "residence-aya",
    description:"<p>Acheter un plateau bureau ou un local commercial offre de nombreux avantages. C’est en effet \
    l’occasion de profiter d’un emplacement très desservis au cœur d'une ville dynamique.</p>\
    <p>1 Résidence / +100 plateaux bureaux et locaux commerciaux</p><br> \
    <p>Superficie : 50 à 150m²</p>",
    caracterstique :[{id:1,
                      title:"Équipements collectifs, avantages et sécurité :",
                      data:["Entrées sécurisées et contrôlées",
                              "Parking en sous-sol avec portail automatique",
                              "Ascenseurs de grande marque avec accès au parking",
                              "Respect des normes parasismique RPS 2000",
                              // eslint-disable-next-line no-multi-str
                              "Contrôle technique du gros œuvre, étanchéité, électricité plomberie, climatisation et \
                              ascenseurs",
                              "Contrôle du béton et du sol réalisé par un laboratoire agrée"]},
                            {id:2,
                              title:"Plateaux bureaux :",
                            data:["Cinq étages et de nombreuses superficies"
                              ,"Revêtement sol en marbre ou compact"
                              ,"Portes de fabrication européenne"
                              ,"Fenêtre en menuiserie d'aluminium"
                              ,"Climatisation individuelle"]},
                            {id:3,
                              title:"Locaux commerciaux :",
                            data:[
                              "Emplacement central" 
                              ,"Excellente visibilité et forte affluence",
                              "Revêtement murs en carreaux"
                              ,"Possibilité de personnaliser les locaux"
                            ]}]
  },
  {
    id: 2,
    name: "Résidence Sofia",
    subtitle:"",
    img: "../images/sofia-cover.jpg",
    path: "residence-sofia",
    description:"",
    caracterstique:[]
  },
 {
    id: 6,
    name: "Résidence Ahlam 1/2/3",
    subtitle:"",
    img: "../images/77.webp",
    path: "residence-ahlam",
    description:"",
    caracterstique:[]
  },
  {
    id: 7,
    name: "Résidence Walili ",
    subtitle:"",
    img: "../images/99.webp",
    path: "residence-walili",
    description:"",
    caracterstique:[]
  },
  {
    id: 3,
    name: "Résidence Ouzoud ",
    subtitle:"",
    img: "../images/88.webp",
    path: "residence-ouzoud",
    description:"",
    caracterstique:[]
  },
  {
    id: 4,
    name: "Résidence Al Azhar ",
    subtitle:"",
    img: "../images/111.webp",
    path: "residence-al-azhar",
    description:"",
    caracterstique:[]
  },
  {
    id: 5,
    name: "Résidence Wiam ",
    subtitle:"",
    img: "../images/222.webp",
    path: "residence-wiam",
    description:"",
    caracterstique:[]
  }
  
];
