import React, { useRef } from "react";
import BestProjectSwiper from "./BestProjectSwiper";

function BestProject() {
  const bestproject = useRef();
  const toggleImg = (img) => () => {
    bestproject.current
      .querySelector(`.bestproject .swiper-button-${img}`)
      .click();
  };

  return (
    <div className="bestproject" id="complexe-amine" ref={bestproject}>
      <div className="container">
        <div className="title">
          <h2>
            Complexe Amine<span>/</span>
            <small>Avenir</small>
          </h2>
        </div>
        <div className="content">
          <div className="content-container">
            <p>Pour répondre aux besoins de nos clients.</p>
            <p>
              Aghouatim Group vous offre l’occasion de profiter de la qualité
              d’une construction pensée selon vos besoins et vos envies, avec le
              projet Complexe Amine, de nombreuses résidences sont en cours de
              construction
            </p>
            <p>
              <strong>
                7 Résidences <span>/</span>+4 étages
                <br /> +100 Appartements <span>/</span> +50 Magasins
              </strong>
            </p>
            {/* <p>
              <strong>Address :</strong>
            </p> */}
            <button>
              Decouvrir <img src="../icons/flech-white-contact.svg" alt="" />
            </button>
          </div>
          <div className="content-slider">
            <div className="btns">
              <button onClick={toggleImg("prev")}>
                <img src="../icons/flech-white.svg" alt="" />
              </button>
              <button onClick={toggleImg("next")}>
                <img src="../icons/flech-white.svg" alt="" />
              </button>
            </div>
            <div className="content-slider-container">
              {/* <img src="../images/prj-1.jpg" alt="" /> */}
              <BestProjectSwiper />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BestProject;
