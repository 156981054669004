import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import Hero from "../components/homepage/Hero";
// import Hero2 from "../components/homepage/Hero2";
import Services from "../components/homepage/Services";
import Engagement from "../components/homepage/Engagement";
import Projects from "../components/homepage/Projects";
import Residences from "../components/homepage/Residences";
import BestProject from "../components/homepage/BestProject";
import Footer from "../components/Footer";
import LoadingScreen from "../pages/LoadingScreen";
import { useLocation } from "react-router-dom";


function HomePage() {
  const [url,seturl]=useState();
  const location=useLocation
  useEffect(() => {
    const hash = window.location.hash === "#complexe-amine";
    const url = window.location.hash === "#/#contact";
    seturl(url)
    console.log(location);
    window.scrollTo(0, 0);
    hash && document.getElementById("complexe-amine").scrollIntoView();
    url && window.scrollTo(0, document.body.scrollHeight);
  }, []);

  const array = [
    {
      link: "Nos engagements",
      path: "engagements",
    },
    {
      link: "Nos entreprises",
      path: "nosentreprises",
    },
    {
      link: "Résidences",
      path: "residences",
    },
    {
      link: "Complexe Amine",
      path: "complexe-amine",
    },
  ];
  return (
    <>
      {!window.location.hash && <LoadingScreen />}

      <div className="homepage">
        <header>
          <NavBar array={array} />
        </header>
        <Hero />
        {/* <Hero2 /> */}
        <main>
          <Services />
          <Engagement />
          <Projects />
          <Residences />
          <BestProject />
          <Footer />
        </main>
      </div>
    </>
  );
}

export default HomePage;
