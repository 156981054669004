import React, { useEffect } from "react";
import NavBar from "../components/NavBar";
import Hero from "../components/ayabrique/Hero";
import Services from "../components/ayabrique/Services";
import Products from "../components/ayabrique/Products";
import Album from "../components/ayabrique/Album";
import Footer from "../components/Footer";
import {Helmet} from "react-helmet";

function AyaBridue() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const array = [
    {
      link: "Services",
      path: "services",
    },
    {
      link: "Produits",
      path: "produits",
    },
    {
      link: "Album",
      path: "album",
    },
  ];

  return (
    <div className="aya-brique">
      <Helmet>
        <title>Aya Brique - Aghouatim</title>
        <link rel="canonical" href="/aya-briques" />
      </Helmet>
      <header>
        <NavBar array={array} />
      </header>
      <Hero />
      <main>
        <Services />
        <Products />
        <Album />
      </main>
      <Footer />
      <div className="brd-cnt">
        <div className="border-1"></div>
        <div className="border-2"></div>
      </div>
    </div>
  );
}

export default AyaBridue;
