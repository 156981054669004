
import {createSlice} from "@reduxjs/toolkit";
import { resetAll } from "../actions/InitializerAction";
import keyvaluestorage from "../storage/keyvaluestorage";
export const globalSlice = createSlice({ 
   name: "globalSlice",
   initialState: {
    residence :{
        id: null,
        name: null,
    }
  },
  reducers:{

    setResidence(state, action) {
        state.residence[action.payload.key] = action.payload.value;
      }
  }}
);
export const {
    setResidence
  } = globalSlice.actions;
export default globalSlice.reducer;