export const pictures =[
    {
        "id":1,
        "images" :[
        "../images/aya (1).webp",
        "../images/aya (2).jpg",
        "../images/aya (3).jpg",
        "../images/aya (4).jpg",
        "../images/aya (5).jpg",
        "../images/aya (6).jpg",
        "../images/aya (7).jpg",
        "../images/aya (8).jpg",
        ]
    },
    {
        "id":2,
        "images" :[
            "../images/sofia (1).jpg",
            "../images/sofia (2).jpg",
            "../images/sofia (3).jpg",
            "../images/sofia (4).jpg",
            "../images/sofia (5).jpg",
            "../images/sofia (6).jpg",
            "../images/sofia (7).jpg",
            "../images/sofia (8).jpg",
            
        ]
    },
    {
        "id":3,
        "images" :[
            "../images/sofia (1).jpg",
            "../images/sofia (2).jpg",
            "../images/sofia (3).jpg",
            "../images/sofia (4).jpg",
            "../images/sofia (5).jpg",
            "../images/sofia (6).jpg",
            "../images/sofia (7).jpg",
            "../images/sofia (8).jpg",
            
        ]
    },
    {
        "id":4,
        "images" :[
            "../images/sofia (1).jpg",
            "../images/sofia (2).jpg",
            "../images/sofia (3).jpg",
            "../images/sofia (4).jpg",
            "../images/sofia (5).jpg",
            "../images/sofia (6).jpg",
            "../images/sofia (7).jpg",
            "../images/sofia (8).jpg",
            
        ]
    },
    {
        "id":5,
        "images" :[
            "../images/sofia (1).jpg",
            "../images/sofia (2).jpg",
            "../images/sofia (3).jpg",
            "../images/sofia (4).jpg",
            "../images/sofia (5).jpg",
            "../images/sofia (6).jpg",
            "../images/sofia (7).jpg",
            "../images/sofia (8).jpg",
            
        ]
    },
    {
        "id":6,
        "images" :[
            "../images/sofia (1).jpg",
            "../images/sofia (2).jpg",
            "../images/sofia (3).jpg",
            "../images/sofia (4).jpg",
            "../images/sofia (5).jpg",
            "../images/sofia (6).jpg",
            "../images/sofia (7).jpg",
            "../images/sofia (8).jpg",
           
        ]
    },
    {
        "id":7,
        "images" :[
            "../images/sofia (1).jpg",
            "../images/sofia (2).jpg",
            "../images/sofia (3).jpg",
            "../images/sofia (4).jpg",
            "../images/sofia (5).jpg",
            "../images/sofia (6).jpg",
            "../images/sofia (7).jpg",
            "../images/sofia (8).jpg",
           
        ]
    },
    {
        "id":8,
        "images" :[
        "../images/sofia (1).jpg",
        "../images/sofia (2).jpg",
        "../images/sofia (3).jpg",
        "../images/sofia (4).jpg",
        "../images/sofia (5).jpg",
        "../images/sofia (6).jpg",
        "../images/sofia (7).jpg",
        "../images/sofia (8).jpg",
        
        ]
    },
    {
        "id":9,
        "images" :[
        "../images/sofia (1).jpg",
        "../images/sofia (2).jpg",
        "../images/sofia (3).jpg",
        "../images/sofia (4).jpg",
        "../images/sofia (5).jpg",
        "../images/sofia (6).jpg",
        "../images/sofia (7).jpg",
        "../images/sofia (8).jpg",
     
        ]
    }
    ]