import React from "react";

function Project_Item({element}) {
  return (
    <div className="item">
      <div className="item-img">
        <img src={`../images/${element.img}`} alt="" />
      </div>
      <div className="item-content">
        <p>{element.name}</p>
      </div>
    </div>
  );
}

export default Project_Item;
