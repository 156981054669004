import React from "react";
import HeroSwiper from "./heroslider/HeroSwiper";

function Hero() {
  return (
    <div className="hero">
      <div className="container">
        <div className="title">
          <h1>Promoteur Immobilier</h1>
          <p>
            Construire des biens qui font du bien, c’est s’engager à mettre tout
            en oeuvre pour faire de nos appartements une source de bien-être,
            avec un impact positif sur notre santé et sur l’environnement.
          </p>
        </div>
        <div className="content">
          <div className="content-img">
            <HeroSwiper />
            {/* <img src="../images/444.webp" alt="" /> */}
          </div>
          <div className="content-container">
            <div className="content-p">
              <p>
                Aghouatim Group, est une référence en matière d’immobilier
                depuis plus de trois générations.
              </p>
              <p>
                Pendant ces 40 dernières années, dans toutes les grandes
                métropoles marocaines, Aghouatim Group a répondu aux enjeux
                architecturaux contemporains.
              </p>
              <p>
                En tant que promoteur immobilier au Maroc, Aghouatim Group met
                un point d’honneur à concevoir des logements respectueux de
                l’environnement à proximité de tous les services et équipements
                proposés par la ville.
              </p>
            </div>
            {/* <div className="small-img">
              <button>
                <img src="../icons/flech-white.svg" alt="" />
              </button>
              <img src="../images/22.webp" alt="" />
              <img src="../images/44.webp" alt="" />
              <img src="../images/55.webp" alt="" />
              <img src="../images/x11.webp" alt="" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
