import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { residences } from "../../assets/data/residences";
import { pictures } from "../../assets/data/Items_residence";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleXmark,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
function Album({ indexx }) {
    // const [id,SetId]=useState();
    // const [name, setName] = useState();
    const id  = localStorage.getItem("idresid");
  const generatIndex = (prevOrNext) => {
  
      var index;
    if (indexx === 0 && prevOrNext === "prev") {
      index = residences.length - 1;
    }
    if (indexx === residences.length - 1 && prevOrNext === "next") {
      index = 0;
    }
    if (indexx !== 0 && prevOrNext === "prev") {
      index = indexx - 1;
    }
    if (indexx !== residences.length - 1 && prevOrNext === "next") {
      index = indexx + 1;
    }
    return index;
  };

  // const images =[
  //   "../images/sofia-1.webp",
  //   "../images/sofia-2.webp",
  //   "../images/sofia-3.webp",
  //   "../images/sofia-4.webp",
  //   "../images/sofia-5.webp",
  //   "../images/sofia-6.webp",
  //   "../images/sofia-7.webp",
  //   "../images/sofia-8.webp",
  //   ]
  const { state } = useLocation();
  const residence = useSelector(state=>state.globalSlice.residence);
  const [open, setOpen] = useState(false);
  const [data,setData]=useState([]);

  const [slideNumber, setSlideNumber] = useState(0);
  
  const handleOpen = (i) => {
    setSlideNumber(i);
    setOpen(true);
  };

  const handleMove = (direction) => {
    let newSlideNumber;

    if (direction === "l") {
      newSlideNumber = slideNumber === 0 ? 5 : slideNumber - 1;
    } else {
      newSlideNumber = slideNumber === 5 ? 0 : slideNumber + 1;
    }

    setSlideNumber(newSlideNumber);
  };
  return (
    <>
  {open && (
            <div className="slider">
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="close"
                onClick={() => setOpen(false)}
              />
              <FontAwesomeIcon
                icon={faCircleArrowLeft}
                className="arrow"
                onClick={() => handleMove("l")}
              />
              <div className="sliderWrapper" 
               Style="-moz-user-select: none;
                -webkit-user-select: none;
                 -ms-user-select:none;
                  user-select:none;
                  -o-user-select:none;"
 unselectable="on"
 onselectstart="return false;" 
 onmousedown="return false;">

              
                <img
                  src={data[slideNumber]}
                  alt=""
                  className="sliderImg"
                />

              </div>
              <FontAwesomeIcon
                icon={faCircleArrowRight}
                className="arrow"
                onClick={() => handleMove("r")}
              />
            </div>
          )}
    <div className="album" id="album">
     
      <div className="container">
        <div className="title">
          <h2>
            Album<span>/</span>
          </h2>
        </div>
        <div className="content">
          <div className="img-container">
         
            {/* <div>
              <div>
                <img src="../images/sofia-7.webp" alt="" />
              </div>
              <div>
                <img src="../images/sofia-8.webp" alt="" />
              </div>
            </div>
            <div>
              <div>
                <img src="../images/sofia-4.webp" alt="" />
              </div>
              <div>
                <img src="../images/sofia-3.webp" alt="" />
              </div>
            </div>
            <div>
              <div>
                <img src="../images/sofia-5.webp" alt="" />
              </div>
              <div>
                <img src="../images/sofia-6.webp" alt="" />
              </div>
            </div>
            <div>
              <div>
                <img src="../images/sofia-1.webp" alt="" />
              </div>
              <div>
                <img src="../images/sofia-2.webp" alt="" />
              </div>
            </div> */}
            
            {
              pictures.map(
              (picture, index) =>
              
                picture.id == id &&
                picture.images.map((img, i) =>
                  i % 2 === 0 ? (
                    
                    <div key={i} className="image-row">
                    
                      <div>
                        <img src={img} alt="" onClick={() => {handleOpen(i);setData(picture.images)}} />
                        
                      </div>
                      {picture.images[i + 1] && (
                        <div>
                          <img src={picture.images[i + 1]} onClick={() => {handleOpen(i + 1);setData(picture.images)}}alt="" />
                        </div>
                      )}
                    </div>
                  ) : ""
                )
            )}
          </div>
        </div>
        {console.table(data)}
        <div className="btns-project">
          <Link
            to={"/project/" + residences[generatIndex("prev")].path}
            // state={{id:residences[generatIndex("prev")].id,name: residences[generatIndex("prev")].name}}
            onClick={()=>{localStorage.clear();
    localStorage.setItem('idresid', residences[generatIndex("prev")].id);
    localStorage.setItem('nameresid',residences[generatIndex("prev")].name);
    console.log(localStorage.getItem("idresid"));}}
            className="btn-prev"
          >
            <svg
              width="34"
              height="20"
              viewBox="0 0 34 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.939339 10.9393C0.353554 11.5251 0.353554 12.4749 0.939339 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97918 12.6066 1.3934C12.0208 0.80761 11.0711 0.807609 10.4853 1.3934L0.939339 10.9393ZM34 10.5L2 10.5L2 13.5L34 13.5L34 10.5Z"
                fill="#696969"
              />
            </svg>
            <span>{residences[generatIndex("prev")].name}</span>
          </Link>
          <Link
            to={"/project/" + residences[generatIndex("next")].path}
            // state={{id:residences[generatIndex("next")].id,name: residences[generatIndex("next")].name}}
            onClick={()=>{localStorage.clear();
    localStorage.setItem('idresid', residences[generatIndex("next")].id);
    localStorage.setItem('nameresid',residences[generatIndex("next")].name);
    console.log(localStorage.getItem("idresid"));}}
            className="btn-next"
          >
          
            <span>{residences[generatIndex("next")].name}</span>
            <svg
              width="34"
              height="20"
              viewBox="0 0 34 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.939339 10.9393C0.353554 11.5251 0.353554 12.4749 0.939339 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97918 12.6066 1.3934C12.0208 0.80761 11.0711 0.807609 10.4853 1.3934L0.939339 10.9393ZM34 10.5L2 10.5L2 13.5L34 13.5L34 10.5Z"
                fill="#696969"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
    </>
  );
}

export default Album;
