import React from "react";

function Album() {
  return (
    <div className="album" id="album">
      <div className="container">
        <div className="title">
          <h2>
            Album<span>/</span>
          </h2>
        </div>
        <div className="content">
          <div className="img-container">
            <div>
              <div>
                <img src="../images/alb-01.webp" alt="" />
              </div>
              <div>
                <img src="../images/alb-02.webp" alt="" />
              </div>
            </div>
            <div>
              <div>
                <img src="../images/alb-04.webp" alt="" />
              </div>
              <div>
                <img src="../images/alb-03.webp" alt="" />
              </div>
            </div>
            <div>
              <div>
                <img src="../images/alb-05.webp" alt="" />
              </div>
              <div>
                <img src="../images/alb-06.webp" alt="" />
              </div>
            </div>
            <div>
              <div>
                <img src="../images/alb-07.webp" alt="" />
              </div>
              <div>
                <img src="../images/alb-08.webp" alt="" />
              </div>
            </div>
          </div>
          <div className="end-hide"></div>
        </div>
      </div>
    </div>
  );
}

export default Album;
