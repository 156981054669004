import gsap from "gsap";
import React from "react";
import { useRef } from "react";
import { useEffect } from "react";

function LoadingScreen() {
    const loader = useRef()
  useEffect(() => {
    const renderHero = gsap.timeline({
      defaults: {
        duration: 2,
        ease: "power4.out",
      },
    });
    renderHero
      .fromTo(
        ".icon_logo",
        {
          alpha: 0,
          transform: "translate(1280px, 690px)",
        },
        {
          alpha: 1,
          transform: "translate(1280px, 685px)",
          duration: 1,
          stagger: 0.5,
          delay: 1,
        }
      )
      .fromTo(
        ".icon_logo",
        {
          transform: "translate(1280px, 685px)",
        },
        {
          transform: "translate(1207px, 685px)",
          duration: 1.5,
        }
      )
      .fromTo(
        ".aghoutim_logo",
        {
          alpha: 0,
          transform: "translate(1217px, 685px)",
        },
        {
          alpha: 1,
          transform: "translate(1207px, 685px)",
          duration: 1,
          stagger: 0.1,
        },
        "-=1"
      )
      .fromTo(
        ".group_logo",
        {
          alpha: 0,
        },
        {
          alpha: 1,
          duration: 1,
        },
        "-=0.5"
      );

    }, []);
    // window.addEventListener('load', () => loader.current.classList.add('hidden')) 
  return (
    <div className="loading-screen" ref={loader}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="193"
        height="36.033"
        viewBox="0 0 193 36.033"
      >
        <g id="Groupe_4" data-name="Groupe 4" transform="translate(-1207 -685)">
          <g id="Groupe_3" data-name="Groupe 3">
            <path
              className="aghoutim_logo"
              data-name="Tracé 1"
              d="M66.16,20.08l-.91-2.14H56.94L56,20.08H52.65L59.34,4.37h3.52l6.69,15.71Zm-8.1-4.81h6.08l-3-7.2Z"
              transform="translate(1207 685)"
              fill="#2a292e"
            />
            <path
              className="aghoutim_logo"
              data-name="Tracé 2"
              d="M77.08,14.15V11.61h7.29v5.88a8.8,8.8,0,0,1-7.05,2.92,8.48,8.48,0,0,1-6.27-2.29,8.1,8.1,0,0,1-2.25-5.88A8.16,8.16,0,0,1,71,6.36a8,8,0,0,1,6-2.34c4,0,6.67,2.16,7.29,5.73H81.15q-.78-2.67-4-2.67c-3.23,0-5.06,2.06-5.06,5.18s1.85,5.25,5.49,5.25a5.72,5.72,0,0,0,3.65-1.07V14.15Z"
              transform="translate(1207 685)"
              fill="#2a292e"
            />
            <path
              className="aghoutim_logo"
              data-name="Tracé 3"
              d="M98.94,4.37h3.26V20.08H98.94v-6h-8.5v6H87.18V4.37h3.26v6.91h8.5Z"
              transform="translate(1207 685)"
              fill="#2a292e"
            />
            <path
              className="aghoutim_logo"
              data-name="Tracé 4"
              d="M106.69,18.08a8.19,8.19,0,0,1-2.23-5.86,8.16,8.16,0,0,1,2.23-5.86,9,9,0,0,1,12.1,0A8.18,8.18,0,0,1,121,12.22a8.08,8.08,0,0,1-2.25,5.86,9,9,0,0,1-12.1,0Zm6-.72A4.73,4.73,0,0,0,116.42,16a5.4,5.4,0,0,0,1.32-3.73,5.19,5.19,0,0,0-1.32-3.7,4.75,4.75,0,0,0-3.68-1.44,4.63,4.63,0,0,0-3.67,1.44,5.3,5.3,0,0,0-1.3,3.7,5.31,5.31,0,0,0,1.3,3.71,4.69,4.69,0,0,0,3.67,1.38Z"
              transform="translate(1207 685)"
              fill="#2a292e"
            />
            <path
              className="aghoutim_logo"
              data-name="Tracé 5"
              d="M123.31,13.28V4.37h3.26v8.91c0,2.71,1.32,4.08,4,4.08s4-1.37,4-4.08V4.37h3.27v8.91q0,7.13-7.26,7.13T123.31,13.28Z"
              transform="translate(1207 685)"
              fill="#2a292e"
            />
            <path
              className="aghoutim_logo"
              data-name="Tracé 6"
              d="M151.33,20.08l-.9-2.14h-8.32l-.91,2.14h-3.39l6.7-15.71H148l6.69,15.71Zm-8.1-4.81h6.07l-3-7.2Z"
              transform="translate(1207 685)"
              fill="#2a292e"
            />
            <path
              className="aghoutim_logo"
              data-name="Tracé 7"
              d="M167,4.09V7h-5.65V19.8H158V7h-5.65V4.09Z"
              transform="translate(1207 685)"
              fill="#2a292e"
            />
            <path
              className="aghoutim_logo"
              data-name="Tracé 8"
              d="M168.41,20.08V4.37h3.26V20.08Z"
              transform="translate(1207 685)"
              fill="#2a292e"
            />
            <path
              className="aghoutim_logo"
              data-name="Tracé 9"
              d="M173.7,20.08V4.37H179l4.24,12.41h.22L187.7,4.37H193V20.08h-3.24V7.85h-.24l-4.27,12.2h-3.81l-4.27-12.2h-.24V20.08Z"
              transform="translate(1207 685)"
              fill="#2a292e"
            />
          </g>
          <g id="Groupe_2" data-name="Groupe 2">
            <path
              className="group_logo"
              data-name="Tracé 10"
              d="M59,31.13V30h5.1v3.88a6,6,0,0,1-5,2.14,6,6,0,0,1-4.48-1.65A5.91,5.91,0,0,1,53,30.09a6,6,0,0,1,1.58-4.23,5.71,5.71,0,0,1,4.33-1.68,4.84,4.84,0,0,1,5.15,3.95H62.67a3.51,3.51,0,0,0-3.74-2.61A4.29,4.29,0,0,0,55.6,26.8a4.66,4.66,0,0,0-1.18,3.29,4.72,4.72,0,0,0,1.21,3.34,4.66,4.66,0,0,0,3.53,1.29,4.71,4.71,0,0,0,3.55-1.28V31.13Z"
              transform="translate(1207 685)"
              fill="#fd5200"
            />
            <path
              className="group_logo"
              data-name="Tracé 11"
              d="M79.67,35.76H78.21L76,31.81a2.68,2.68,0,0,1-.4,0H71v3.93H69.6V24.42h6c2.75,0,4,1.46,4,3.71a3.23,3.23,0,0,1-2.32,3.47ZM71,30.51h4.35c1.89,0,2.88-.65,2.88-2.38s-1-2.42-2.88-2.42H71Z"
              transform="translate(1207 685)"
              fill="#fd5200"
            />
            <path
              className="group_logo"
              data-name="Tracé 12"
              d="M85.57,34.32A6,6,0,0,1,84,30.09a5.89,5.89,0,0,1,1.58-4.21,6.3,6.3,0,0,1,8.59,0,5.87,5.87,0,0,1,1.59,4.21,5.93,5.93,0,0,1-1.59,4.23A5.6,5.6,0,0,1,89.86,36,5.66,5.66,0,0,1,85.57,34.32Zm7.58-.94a4.72,4.72,0,0,0,1.17-3.29,4.66,4.66,0,0,0-1.17-3.27,4.81,4.81,0,0,0-6.58,0,4.74,4.74,0,0,0-1.16,3.27,4.69,4.69,0,0,0,1.16,3.27,4.84,4.84,0,0,0,6.58,0Z"
              transform="translate(1207 685)"
              fill="#fd5200"
            />
            <path
              className="group_logo"
              data-name="Tracé 13"
              d="M100.88,24.42h1.42v6.75c0,2.32,1.19,3.49,3.57,3.49s3.57-1.17,3.57-3.49V24.42h1.43v6.74c0,3.17-1.7,4.84-5,4.84s-5-1.67-5-4.84Z"
              transform="translate(1207 685)"
              fill="#fd5200"
            />
            <path
              className="group_logo"
              data-name="Tracé 14"
              d="M122.1,24.42c2.92,0,4.32,1.56,4.32,4.13S125,32.7,122.1,32.7h-4.31v3.06h-1.41V24.42Zm-.22,7c2,0,3.12-.8,3.12-2.83s-1.11-2.83-3.12-2.83h-4.09v5.66Z"
              transform="translate(1207 685)"
              fill="#fd5200"
            />
          </g>
          <g id="Groupe_1" data-name="Groupe 1">
            <path
              className="icon_logo icon_logo-3"
              data-name="Tracé 17"
              d="M26.27,31.13V36H16.73V31.13l4.77-4.86Z"
              transform="translate(1207 685)"
              fill="#fd5200"
            />
            <path
              className="icon_logo icon_logo-2"
              data-name="Tracé 16"
              d="M43,31.59V36H38.67L21.5,18.49,4.32,36H0V31.59l6.11-6.23L21.5,9.67,36.88,25.36Z"
              transform="translate(1207 685)"
              fill="#fd5200"
            />
            <path
              className="icon_logo icon_logo-1"
              data-name="Tracé 15"
              d="M43,0V27.67l-6.11-6.23V6.23H6.11V21.44L0,27.67V0Z"
              transform="translate(1207 685)"
              fill="#fd5200"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default LoadingScreen;
