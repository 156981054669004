
import {configureStore} from "@reduxjs/toolkit";
import globalSlice from "./reducers/globalSlice";

export default configureStore({
    reducer: {
        globalSlice: globalSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({serializableCheck: false}),

})