import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";

export default function App() {
  return (
    <>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={0}
        pagination={{
          type: "fraction",
        }}
        // loop={true}
        // loopFillGroupWithBlank={true}
        navigation={true}
        className="mySwiper"
        modules={[Pagination, Navigation]}
      >
        <SwiperSlide>
          <img src="../images/amin-11.webp" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="../images/44.webp" alt="" />
        </SwiperSlide>
        {/* <SwiperSlide>
          <img src="../images/66.webp" alt="" />
        </SwiperSlide> */}
        <SwiperSlide>
          <img src="../images/777.webp" alt="" />
        </SwiperSlide>
        {/* <SwiperSlide>
          <img src="../images/88.webp" alt="" />
        </SwiperSlide> */}
      </Swiper>
    </>
  );
}
