import React from "react";

function Hero() {
  return (
    <div className="hero">
      <div className="container">
        <div className="title">
          <h1>Société Yamani</h1>
          <p>Vente, Importation</p>
        </div>
        <div className="content">
          <div className="content-img">
            <img src="../images/prj-1.webp" alt="" />
          </div>
          <div className="content-container">
            <div className="content-p">
              <p>
                La Société Yamani est un leader dans son domaine de
                l’importation de toutes sortes de matériaux de construction.
              </p>

              <p>
                Pour vos travaux de construction, la Société Yamani, vous
                accompagnes dans le choix du matériel dont vous avez besoin pour
                mener à bien votre projet.
              </p>

              <p>
                <span>Pour nous rendre visite</span>
                <br />
                <strong>Address:</strong> Villa B4 Lotissement Ali Ouhmad BD
                Prince MY Abdellah, Marrakech
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
