import React from "react";

function Products() {
  return (
    <div className="products" id="produits">
      <div className="container">
        <div className="title">
          <h2>
            Produits<span>/</span>
          </h2>
        </div>
        <div className="content">
        <div className="subtitle">
          <h3>
            Briques rouge
            <hr />
          </h3>
        </div>
          <div className="item-container">
            <div className="item">
              <img src="../images/prd-01.webp" alt="" />
              <h4>7cm</h4>
            </div>
            <div className="item">
              <img src="../images/prd-02.webp" alt="" />
              <h4>10cm</h4>
            </div>
            <div className="item">
              <img src="../images/prd-03.webp" alt="" />
              <h4>15cm</h4>
            </div>
            <div className="item">
              <img src="../images/prd-04.webp" alt="" />
              <h4>25cm</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
