import React from "react";
import { Link } from "react-router-dom";

function Projects() {
  return (
    <div className="projects" id="nosentreprises">
      <div className="container">
        <Link to="/aya-briques" className="item">
          <div className="item-img">
            <img src="../images/prj-2.webp" alt="" />
          </div>
          <div className="item-content">
            <h2>
              AYA Briques<span>/</span>
            </h2>
            <button>
              Decouvrir <img src="../icons/flech-white.svg" alt="" />
            </button>
          </div>
        </Link>
        <Link to="/yamani" className="item">
          <div className="item-img">
            <img src="../images/prj-1.webp" alt="" />
          </div>
          <div className="item-content">
            <h2>
              Société Yamani<span>/</span>
            </h2>
            <button>
              Decouvrir <img src="../icons/flech-white.svg" alt="" />
            </button>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Projects;
