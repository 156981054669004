import React from "react";

function Services() {
  return (
    <div className="service">
      <div className="container">
        <div className="title">
          <h2>
            Services<span>/</span>
          </h2>
        </div>
        <div className="content">
          <div className="item">
            <div className="item-title">
              <span>1</span>
              <h3>Aménagement de terrains</h3>
            </div>
            <p>
              Nous exerçons notre métier depuis 40 ans. Nous rassemblons un
              large panel de compétences techniques, administratives,
              financières et commerciales.
            </p>
          </div>
          <div className="item">
            <div className="item-title">
              <span>2</span>
              <h3>Construction des biens</h3>
            </div>
            <p>
              Pour des constructions les plus traditionnelles au plus modernes.
              Nous vous accompagnant pour construire le bien qui vous ressemble
              dans le respect de votre budget.
            </p>
          </div>
          <div className="item">
            <div className="item-title">
              <span>3</span>
              <h3>Promotion immobilière</h3>
            </div>

            <p>
              Vous envisagez d’acheter un bien immobilier pour réaliser un
              investissement locatif ou y habiter? Être accompagné par nous, est
              un gage de réussite de votre projet.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
