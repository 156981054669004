import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="container" id="footer">
        <div className="map">
          <div className="content">
            <h2>Nous Somme toujours ici</h2>
            <div>
              <p>
                Villa B4 Lotissement Ali Ouhmad BD Prince MY Abdellah,
                Marrakech, Maroc
              </p>
              <div>
                <div>
                  <a href="tel:+212524449534">+212 5 24 44 95 34</a>
                  <a href="tel:+212661053981">+212 6 61 05 39 81</a>
                </div>
                <div>
                  <a href="mailto:steyamani@gmail.com">Steyamani@gmail.com</a>
                  <a href="mailto:contact@aghouatim.com">
                    contact@aghouatim.com
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="map-container">
            <img src="../images/map.webp" alt="" />
          </div>
        </div>
        <div className="footer">
          <nav>
            <a href="/">
              <img src="../icons/logo-white.svg" alt="Aghouatim logo" />
            </a>
            <div className="reseaux">
              <h4>Follow Us</h4>
              <div>
                <Link to="/">
                  <img src="../icons/fb.svg" alt="Page Facebook" />
                </Link>
                <Link to="/">
                  <img src="../icons/insta.svg" alt="Page Instagram" />
                </Link>
                <Link to="/">
                  <img src="../icons/linkedin.svg" alt="Page LinkedIn" />
                </Link>
              </div>
            </div>
            <ul>
              <li>
                <Link to="/aya-briques" onClick={() => window.scrollTo(0, 0)}>
                  AYA Briques
                </Link>
              </li>
              <li>
                <Link
                  to="/#complexe-amine"
                  onClick={() => document.getElementById("complexe-amine").scrollIntoView()}
                >
                  Complexe Amine
                </Link>
              </li>
              <li>
                <Link to="/yamani" onClick={() => window.scrollTo(0, 0)}>
                  Société Yamani
                </Link>
              </li>
            </ul>
          </nav>
          <div className="sub-footer" id="contact">
            <p>Copyright 2022 | Aghouatim Group</p>
            <div>
              <a href="https://offzone.net/" target="_blank" rel="noreferrer">
                <p>By:</p>
                <img src="../icons/offzone.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
