import React, { useEffect, useRef } from "react";
import { useState } from "react";

function Album() {
  const name_box = useRef();
  const [cord, setCord] = useState({ x: 0, y: 0 });

  const addStyle = (context) => () => {
    context && (name_box.current.textContent = context);
    name_box.current.style = `opacity: 1; top:${cord.y}px; left:${cord.x}px; visibility: visible;`;
  };
  const removeStyle = () =>
    (name_box.current.style = `opacity: 0; visibility: hidden;`);
  useEffect(() => {
    window.addEventListener("mousemove", (e) =>
      setCord({ x: e.pageX, y: e.pageY })
    );
  }, []);

  return (
    <div className="products" id="produits">
      <span className="product-box" ref={name_box}></span>

      <div className="container">
        <div className="title">
          <h2>
            Produits<span>/</span>
          </h2>
        </div>
        <div className="content">
          <div className="img-container">
            <div
              onMouseMove={addStyle("Baguette d’Angle")}
              onMouseLeave={removeStyle}
            >
              <img src="../images/p-1.webp" alt="" />
              {/* <span className="product-name">Baguette d’Angle</span> */}
            </div>

            <div
              onMouseMove={addStyle("Rouleau Bitumei")}
              onMouseLeave={removeStyle}
            >
              <img src="../images/p-2.webp" alt="" />
              {/* <span className="product-name">Rouleau Bitumei</span> */}
            </div>

            <div
              onMouseMove={addStyle("Colle Tradicime")}
              onMouseLeave={removeStyle}
            >
              <img src="../images/p-3.webp" alt="" />
              {/* <span className="product-name">Colle Tradicime</span> */}
            </div>

            <div
              onMouseMove={addStyle("Tubes Orange")}
              onMouseLeave={removeStyle}
            >
              <img src="../images/p-4.webp" alt="" />
              {/* <span className="product-name">Tubes Orange</span> */}
            </div>

            <div onMouseMove={addStyle("Ciment")} onMouseLeave={removeStyle}>
              <img src="../images/p-5.webp" alt="" />
              {/* <span className="product-name">Ciment</span> */}
            </div>

            <div
              onMouseMove={addStyle("Fer à Béton")}
              onMouseLeave={removeStyle}
            >
              <img src="../images/p-6.webp" alt="" />
              {/* <span className="product-name">Fer à Béton</span> */}
            </div>

            <div
              onMouseMove={addStyle("Rouleau Grillage")}
              onMouseLeave={removeStyle}
            >
              <img src="../images/p-7.webp" alt="" />
              {/* <span className="product-name">Rouleau Grillage</span> */}
            </div>

            <div
              onMouseMove={addStyle("Replacement")}
              onMouseLeave={removeStyle}
            >
              <img src="../images/p-8.webp" alt="" />
              {/* <span className="product-name">Replacement</span> */}
            </div>

            <div
              onMouseMove={addStyle("Seaux Caoutchouc")}
              onMouseLeave={removeStyle}
            >
              <img src="../images/p-9.webp" alt="" />
              {/* <span className="product-name">Seaux Caoutchouc</span> */}
            </div>
            <div className="disable-hover('Fil d'Attache')">
              <img src="../images/p-10.webp" alt="" />
              {/* <span className="product-name">Fil d'Attache</span> */}
            </div>
            <div className="disable-hover('Fil Galvanise')">
              <img src="../images/p-11.webp" alt="" />
              {/* <span className="product-name">Fil Galvanise</span> */}
            </div>
            <div className="disable-hover('Webercol')">
              <img src="../images/p-12.webp" alt="" />
              {/* <span className="product-name">Webercol</span> */}
            </div>
          </div>
          <div className="end-hide">et plus d'autres produits</div>
        </div>
      </div>
    </div>
  );
}

export default Album;
