import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import "./styles.css";

// import required modules
import { Autoplay, EffectFade, FreeMode, Navigation, Thumbs } from "swiper";

export default function App() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        loop={true}
        spaceBetween={10}
        navigation={true}
        slidesPerView={"auto"}
        slidesPerGroup={1}
        centeredSlides={false}
        centeredSlidesBounds={false}
        loopFillGroupWithBlank={true}
        // autoplay={{
        //   delay: 4000,
        //   disableOnInteraction: false,
        // }}
        effect={"fade"}
        speed={1000}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[Autoplay, EffectFade, FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        <SwiperSlide>
          <img src="../images/444.webp" alt="" />
        </SwiperSlide>
        {/* <SwiperSlide>
          <img src="../images/22.webp" alt="" />
        </SwiperSlide> */}
        <SwiperSlide>
          <img src="../images/44.webp" alt="" />
        </SwiperSlide>
        {/* <SwiperSlide>
          <img src="../images/55.webp" alt="" />
        </SwiperSlide> */}
        <SwiperSlide>
          <img src="../images/amin-1.webp" alt="" />
        </SwiperSlide>
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={0}
        slidesPerView={"auto"}
        loop={false}
        // virtualTranslate={true}
        // navigation={true}
        // freeMode={true}
        // watchSlidesProgress={true}

        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src="../images/444.webp" alt="" />
        </SwiperSlide>
        {/* <SwiperSlide>
          <img src="../images/sofia-7.webp" alt="" />
        </SwiperSlide> */}
        <SwiperSlide>
          <img src="../images/44.webp" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="../images/amin-1.webp" alt="" />
        </SwiperSlide>
        {/* 
        <SwiperSlide>
        <img src="../images/x11.webp" alt="" />
        </SwiperSlide>
         */}
      </Swiper>
      <button
        onClick={() => document.querySelector(".swiper-button-next").click()}
        className="swiper-btn"
      >
        <img src="../icons/flech-white.svg" alt="" />
      </button>
    </>
  );
}
