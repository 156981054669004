import React from "react";
import HomePage from "./pages/HomePage";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import NavBar from "./components/NavBar";
import Hero2 from "./components/homepage/Hero2";
import AyaBridue from "./pages/AyaBridue";
import Yamani from "./pages/Yamani";
import Project from "./pages/Project";
import Cursor from "./components/Cursor";
import LoadingScreen from "./pages/LoadingScreen";
import { Provider } from "react-redux";
import store from "./redux/store";
function App() {
 // <LoadingScreen />
  return (
    <Provider store={store}>
    <div className="App">
      <span id="top"></span>
      <HashRouter>
        <Routes>
          <Route index path="/" element={<HomePage />} />
          <Route index path="/aya-briques" element={<AyaBridue />} />
          <Route index path="/yamani" element={<Yamani />} />
          <Route index path="/project/:residence" element={<Project />} />
          
          {/* <Route
            path="/slidertest"
            element={
              <div className="homepage2">
                <header>
                  <NavBar />
                </header>
                <Hero2 />
              </div>
            }
          /> */}
          {/* <NavBar />
            <Hero2 />
          </Route> */}
        </Routes>
      </HashRouter>
      <Cursor />
    </div>
    </Provider>
  );
}

export default App;
