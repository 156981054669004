import React, { useEffect } from "react";
import NavBar from "../components/NavBar";
import Album from "../components/yamani/Album";
import Hero from "../components/yamani/Hero";
import Services from "../components/yamani/Services";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

function Yamani() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const array = [
    {
      link: "Services",
      path: "services",
    },
    {
      link: "Produits",
      path: "produits",
    },
  ];
  return (
    <div className="yamani">
      <Helmet>
        <title>Yamani - Aghouatim</title>
        <link rel="canonical" href="/yamani" />
      </Helmet>
      <header>
        <NavBar array={array} />
      </header>
      <Hero />
      <main>
        <Services />
        <Album />
        <Footer />
      </main>
      <div className="brd-cnt">
        <div className="border-1"></div>
        <div className="border-2"></div>
      </div>
    </div>
  );
}

export default Yamani;
