import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import Hero from "../components/project/Hero";
import Caracteristiques from "../components/project/Caracteristiques";
import Album from "../components/project/Album";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";
import { residences } from "../assets/data/residences";
import {Helmet} from "react-helmet";

function Project(id) {
  const { residence } = useParams();
  const [res, setRes] = useState({});
  const [indexx, setIndexx] = useState();
 
  useEffect(() => {
    //document.querySelector(".contact").href = "/#contact";
    residences.forEach((x, index) => {
      if (x.path === residence) {
        setRes({ ...x, ix: index });
        setIndexx(index + 1);
      }
    });
    document.title = res.name + ' - Aghouatim';
  }, [res.name, residence]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const array = [
    {
      link: "Caractéristiques",
      path: "caracteristiques",
    },
    {
      link: "Album",
      path: "album",
    },
  ];
  return (
    <div className="project-page">

      <Helmet>
        {/* <title>{indexx} - Aghouatim</title> */}
        <link rel="canonical" href={'/project/' + residence} />
      </Helmet>

      <header>
        <NavBar array={array} />
      </header>
      <Hero element={res} />
      {console.log(res)}
      <Caracteristiques residence={res} id={id}/>
      {indexx && <Album indexx={indexx - 1} id={id} />}
      <Footer />
      <div className="brd-cnt">
        <div className="border-1"></div>
        <div className="border-2"></div>
      </div>
    </div>
  );
}

export default Project;
