import React from "react";

function Services() {
  return (
    <div className="service" id="services">
      <div className="container">
        <div className="title">
          <h2>
            Services<span>/</span>
          </h2>
        </div>
        <div className="content">
          <div className="item">
            <div className="item-title">
              <span>1</span>
              <h3>Fabrication des briques</h3>
            </div>
            <p>
              Nous fabriquons des carreaux de terre cuite, des briques
              réfractaires pour four à bois, des tuiles et hourdis pour maison,
              appartement, villa et tout autre produit de terre cuite.
            </p>
          </div>
          <div className="item">
            <div className="item-title">
              <span>2</span>
              <h3>Particuliers</h3>
            </div>
            <p>
              Pour une rénovation ou construction nouvelle. La fabrication d’une
              petite quantité est possible, afin de répondre aux besoins de nos
              chers clients particuliers.
            </p>
          </div>
          <div className="item">
            <div className="item-title">
              <span>3</span>
              <h3>Professionnels</h3>
            </div>

            <p>
              Pour des grands chantiers (Résidences, Ponts, Villas, etc). Nous
              sommes capable de vous fournir les quantités, dont vous avez
              besoin pour réussir vos investissements.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
