import React, { useEffect, useRef } from "react";

function Cursor() {
  const cursor = useRef();

  const upDateCursor = (e) => {
    cursor.current.style.top = e.pageY + "px";
    cursor.current.style.left = e.pageX + "px";

    const computed = window.getComputedStyle(e.target)["cursor"];

    if (computed === "pointer") cursor.current.classList.add("cursor-active");
    else cursor.current.classList.remove("cursor-active");
  };

  useEffect(() => {
    window.addEventListener("mousemove", upDateCursor);
    const work = document.querySelector(".swiper-wrapper");
    work &&
      work.addEventListener("mouseenter", () =>
        cursor.current.classList.add("drag")
      );
    work &&
      work.addEventListener("mouseleave", () =>
        cursor.current.classList.remove("drag")
      );
  }, []);

  return (
    <>
      <div className="cursor" ref={cursor}>
        <svg
          width="11"
          height="10"
          viewBox="0 0 11 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.268 5.1342V10.0001H0.725227V5.1342L5.49911 0.27002L10.268 5.1342Z"
            fill="#fd5200"
          />
        </svg>
      </div>
    </>
  );
}

export default Cursor;
