import React, { useEffect } from "react";

function Hero({element}) {
  return (
    <div className="hero">
      <div className="container">
        <div className="title">
          <h1>{element.name}</h1>
          {element.subtitle!==""?<p>{element.subtitle}</p>:<p>Appartements, Magasins</p>}
          
        </div>
        <div className="content">
          <div className="content-img">
            <img src={element.img} alt="" />
          </div>
          <div className="content-container">
            <div className="content-p">
            {
              element.description!==""? <div dangerouslySetInnerHTML={{ __html: element.description }} />:
              <>
              <p>
                Acheter un logement neuf offre de nombreux avantages. C’est en
                effet l’occasion de profiter de la qualité d’une construction
                pensée selon vos besoins et vos envies, avec des espaces
                optimisés à la fois confortables, économes et proche toutes
                commodités.
              </p>

              <p>
                3 Résidences / 105 appartements <br />
                Superficie : 60 à 80m² / Apprt <br />x Magasins
              </p>

              <p>
                <strong>Disponibilité:</strong> 13 appartements et x Magasins
              </p>
              <p>
                {/* <strong>Address:</strong> ............................. */}
              </p>
              </>
            }
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
